import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Character1 from '../assets/characters/1.png'
import Character2 from '../assets/characters/2.png'
import Character3 from '../assets/characters/3.png'
import Character4 from '../assets/characters/4.png'
import Character5 from '../assets/characters/5.png'
import Character6 from '../assets/characters/6.png'
import Character7 from '../assets/characters/7.png'
import Character8 from '../assets/characters/8.png'
import Character9 from '../assets/characters/9.png'
import Character10 from '../assets/characters/10.png'
import Character11 from '../assets/characters/11.png'
import Character12 from '../assets/characters/12.png'
import Character13 from '../assets/characters/13.png'
import Character14 from '../assets/characters/14.png'
import Character15 from '../assets/characters/15.png'
import Character16 from '../assets/characters/16.png'
import Character17 from '../assets/characters/17.png'
import Character18 from '../assets/characters/18.png'
import Character19 from '../assets/characters/19.png'

function Explore() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5, 
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 2000,
    pauseOnHover: false, 
    cssEase: 'ease-in-out',
    draggable: false,
    arrows: true, 
    swipe: false,
    centerMode: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4, 
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3, 
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2, 
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1, 
        },
      },
    ],
  };

  return (
    <div className='explore' id='collectionComponent'>
      <div className='titleContainer'>
        <p className='title'>The <span>Collection</span></p>
        <p className='description'>
          <span className='default text'>Created to encapsulate the essence of music and downtown culture.</span>
          <span className='mobile text'>Created to encapsulate the essence of music and downtown culture.</span>
        </p>
      </div>
      <div className='collection'>
        <Slider {...settings}>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character1} alt='Character 1'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character2} alt='Character 2'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character3} alt='Character 3'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character4} alt='Character 4'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character5} alt='Character 5'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character6} alt='Character 6'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character7} alt='Character 7'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character8} alt='Character 8'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character9} alt='Character 9'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character10} alt='Character 10'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character11} alt='Character 11'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character12} alt='Character 12'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character13} alt='Character 13'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character14} alt='Character 14'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character15} alt='Character 15'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character16} alt='Character 16'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character17} alt='Character 17'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character18} alt='Character 18'/>
          </div>
          <div className='imageContainer'>
            <img width="279" height="390" src={Character19} alt='Character 19'/>
          </div>
        </Slider>
      </div>
    </div>
  );
}


export default Explore;
