import React, { useState, useEffect } from 'react';

function FAQ() {
  return (
    <div className='faq' id='faqComponent'>
      <p className='title'>FAQ</p>
      <div className='accordionContainer'>
        <div className="accordion-item">
          <input type="checkbox" id="accordion1"/>
          <label htmlFor="accordion2" className="accordion-item-title"><span className="icon"></span><p>How much will the NFT characters cost?</p></label>
          <div className="accordion-item-desc">TBA</div>
        </div>
        <div className="accordion-item">
          <input type="checkbox" id="accordion2"/>
          <label htmlFor="accordion2" className="accordion-item-title"><span className="icon"></span><p>How many NFT characters are you releasing?</p></label>
          <div className="accordion-item-desc">6000</div>
        </div>
        <div className="accordion-item">
          <input type="checkbox" id="accordion3"/>
          <label htmlFor="accordion2" className="accordion-item-title"><span className="icon"></span><p>How do I purchase Crypto/ NFTs?</p></label>
          <div className="accordion-item-desc">Heres a 10 minute <a href='https://www.jpg.store/articles/intro-to-cardano-nfts' target='_blank'>guide</a> giving you a quick tour of NFTs and how to buy one.</div>
        </div>
        <div className="accordion-item">
          <input type="checkbox" id="accordion4"/>
          <label htmlFor="accordion2" className="accordion-item-title"><span className="icon"></span><p>Selectors runs on a seasonal basis, how long is a season?</p></label>
          <div className="accordion-item-desc">6-8 months. The season is complete once the seasonal perks have been released.</div>
        </div>
        <div className="accordion-item">
          <input type="checkbox" id="accordion5"/>
          <label htmlFor="accordion2" className="accordion-item-title"><span className="icon"></span><p>So each season is a standalone bringing their own unique perks, does this mean I will have to purchase each season seperately?</p></label>
          <div className="accordion-item-desc">Yes each season is a standalone and will have to be purchased seperately. We will be treating each season like its own exhibition, all seasons will have their own fresh and unique twist.</div>
        </div>
        <div className="accordion-item">
          <input type="checkbox" id="accordion6"/>
          <label htmlFor="accordion2" className="accordion-item-title"><span className="icon"></span><p>So to be clear, the first season will come free with the purchase of my Selectors character?</p></label>
          <div className="accordion-item-desc">Thats right! Special 2 for 1 baby.</div>
        </div>
      </div>
     
    </div>
  );
}

export default FAQ;
